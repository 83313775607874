import React, { Component } from 'react'
import logo from '../assets/images/logo.svg'
import logoMobile from '../assets/images/logo-mobile.svg'

import Link from '../utils/link'

class Header extends Component {

  state = {
    menuActive: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    let st = window.pageYOffset || window.scrollY

    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  render() {

    let { menuActive, scrolled } = this.state

    let props = {
      onClick: this._toggleOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    let headerPaddingClass = 'header--padding'
    if (scrolled) headerClass += ' header--scrolled'
    if (scrolled) headerPaddingClass += ' header--scrolled'

    return(
      <>
        <section className={headerClass}>
          <div className='header__inner'>
            <Link className='header__logo' to='/'>
              <img src={logo} alt='Spruce Real Estate' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/property-management'>Management</Link></li>
                <li><Link to='/tenants'>Tenants</Link></li>
                <li><Link to='/listings'>Listings</Link></li>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
              </ul>
            </nav>
            <button className={ menuActive ? 'header__hamburger active' : 'header__hamburger' } onClick={this._toggleOffCanvas}>
              <span className='lines'></span>
            </button>
          </div>
        </section>
        <div className={`off-canvas ${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
          <div className='off-canvas__inner'>
            <img src={logoMobile} alt='Spruce Real Estate' />
            <ul>
              <li><Link to='/property-management' {...props}>Management</Link></li>
              <li><Link to='/tenants' {...props}>Tenants</Link></li>
              <li><Link to='/listings' {...props}>Listings</Link></li>
              <li><Link to='/about' {...props}>About</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className={headerPaddingClass} />
      </>
    )
  }
}

export default Header
