import React from 'react'
import PropTypes from 'prop-types'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <main>
    <Header/>
    { children }
    <Footer/>
  </main>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout