import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"

import Link from '../utils/link'

import logo from '../assets/images/logo-dark.svg'
import { LinkedIn, Facebook, WhatsApp, Instagram, RealEstate } from './icons'

class Footer extends Component {

  render() {
    const { options } = this.props.data.wp.siteSettings

    return(
      <>
        <section className='footer'>
          <div className='footer__inner'>
            <div className='footer__info'>
              <p dangerouslySetInnerHTML={{ __html: options.address.split('\n').join('<br />') }} />
            </div>
            <div className='footer__image-links'>
              <img src={logo} alt='Spruce Real Estate' onClick={() => typeof window !== 'undefined' && window.scrollTo({ top: 0, behavior: 'smooth' })} />
            </div>
            <div className='footer__socials'>
              <Link to={`tel:${options.phone}`}>{ options.phone }</Link>
              <Link to={`mailto:${options.email}`}>{ options.email }</Link>
              <ul>
                { options.whatsapp && <li><Link to={options.whatsapp}><WhatsApp color={'#333333'} /></Link></li> }
                { options.rea && <li><Link to={options.rea}><RealEstate color={'#333333'} /></Link></li> }
                { options.instagram && <li><Link to={options.instagram}><Instagram color={'#333333'} /></Link></li> }
                { options.facebook && <li><Link to={options.facebook}><Facebook color={'#333333'} /></Link></li> }
                { options.linkedin && <li><Link to={options.linkedin}><LinkedIn color={'#333333'} /></Link></li> }
              </ul>
            </div>
            <p className='footer__bottom-text'>© Spruce Real Estate {new Date().getFullYear()}, All Rights Reserved. Design <Link to='https://www.lbdstudios.com.au'>LBD Studios</Link> + <Link to='https://www.atollon.com.au'>Atollon</Link></p>
          </div>
        </section>
      </>
    )
  }
}

export default function FooterWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          wp {
            siteSettings {
              options {
                address
                phone
                email

                whatsapp
                rea
                instagram
                facebook
                linkedin
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}
